import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function ClientPanel() {
  return (
    <>
      <Header />
      <h1>ClientPanel</h1>
      <Footer />
    </>
  );
}
