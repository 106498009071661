import React, { useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  // State to hold form data
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      setError("Please fill in both fields");
      return;
    }
    //let username = email;
    console.log(JSON.stringify({ username, password }));

    try {
      const response = await fetch("http://localhost:8000/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.log(data.message);

      if (data.message.toLowerCase() === "login successful") {
        setSuccessMessage(data.message);
        navigate("/client");
        setError("");
      } else {
        setError(data.message);
        setSuccessMessage("");
      }

      // Reset the form after submission
      setUsername("");
      setPassword("");
    } catch (error) {
      console.error("Error during login:", error);
      setError("Something went wrong. Please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <>
      <Header />
      <div className='container-fluid mt-4  align-items-center'>
        <div className='row d-flex justify-content-center'>
          <div className='col-12 col-md-6 col-lg-4'>
            <div className='card shadow-lg border-0'>
              <div className='card-body p-4'>
                <h3 className='text-center mb-4'>Login</h3>

                {error && <div className='alert alert-danger'>{error}</div>}
                {successMessage && (
                  <div className='alert alert-success'>{successMessage}</div>
                )}

                <form onSubmit={handleSubmit}>
                  <div className='mb-3'>
                    <label htmlFor='formEmail' className='form-label'>
                      Username
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='formEmail'
                      placeholder='Enter your username'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='formPassword' className='form-label'>
                      Password
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      id='formPassword'
                      placeholder='Enter your password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <button type='submit' className='btn btn-primary w-100'>
                    Log In
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
